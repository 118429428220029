import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //apiUrl = 'https://ipdf.shinetechpaperless.com/api/';
  // apiUrl = 'https://live-shinetech-ipdf-slot-shinetech-ipdf.azurewebsites.net/api/';  

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private router: Router
    ) { }

    baseUrl = environment.baseUrl2;

  login (data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'loginapi', data)
      .pipe(
        tap(_ => this.log('login')),
        catchError(this.handleError('login', []))
      );
  }

  logout (): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'signout')
      .pipe(
        tap(_ => this.log('logout')),
        catchError(this.handleError('logout', []))
      );
  }

  // public IdentityVerify(){
  //   this.storage.get("userprofile").then(profile =>{
  //     if(!profile)
  //     {
  //       this.router.navigate(["/login"]);
  //     }
  //   });
  // }

  public IdentityVerify(profileId=null){
    var storageKey="userprofile";
    if(profileId && profileId.length)
    {
      storageKey+=("_"+profileId);
    }
    this.storage.get(storageKey).then(profile =>{      
      if(!profile)
      {
        this.router.navigate(["/login"]);
      }
    });
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}